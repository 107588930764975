<template>
    <div class="gst-order-confirmation__hotel-info">
        <div class="image-container">
            <img alt="" :src="hotelImageSrc" />
        </div>
        <div class="info-container">
            <div class="event-title-container">
                <span class="event-title">{{ hotel.name }}</span>
                <StarRating :value="hotel.award.rating" class="event-rating" />
            </div>
            <div
                v-if="hotel.supplierConfirmationNumber"
                class="info-container__confirmation-number mb-4">
                {{ hotelConfirmationNumber }}
            </div>
            <div
                v-if="showGiftCard"
                class="mb-4">
                <GiftCardChip :amount="giftCard.amount" :currency="giftCard.currency" />
                <BaseTooltip
                    :text="$t('giftCardTooltip')"
                    :open-on-click="!$vuetify.breakpoint.mdAndUp"
                    :open-on-hover="$vuetify.breakpoint.mdAndUp"
                    class-content="gst-order-confirmation__hotel-info__tooltip"
                    class-activator="gst-order-confirmation__hotel-info__tooltip-activator ml-4 ml-md-2">
                    <BaseIcon symbol-id="icons--help" class="gst-order-confirmation__hotel-info__icon" :width="18" :height="18" />
                </BaseTooltip>
            </div>
            <div class="mb-2">
                <div class="info-container__wrapper-icon d-flex justify-center">
                    <CalendarIcon />
                </div>
                <span class="ml-2">
                    {{ period }}
                </span>
            </div>
            <div class="mb-0">
                <div class="info-container__wrapper-icon d-flex justify-center">
                    <LocationIcon />
                </div>
                <span class="ml-2">
                    {{ hotel.address | fullLocation }}
                </span>
            </div>
            <a :href="directionsUrl" target="_blank" class="directions"><strong>{{ $t('getDirections') }}</strong></a>
            <div class="mt-2 mb-3">
                <HotelCancellationPolicy
                    v-if="!isPackageOffer && hotel.roomCancellationPolicies"
                    :cancellation-policies="hotel.roomCancellationPolicies"
                    :currency="hotel.currency"
                    :base-price="hotel.roomRate"
                    :accomodation-nights="hotel.accommodationNights" />
            </div>
        </div>
    </div>
</template>

<script>
    import { getLang as documentUtilsGetLang } from '@core/utils/documentUtils';
    import StarRating from '@core/shared/components/misc/StarRating.vue';
    import { getRouteBetweenTwoPoints, getRouteToPoint } from '@core/utils/locationMapUtils';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import offerConstants from '@core/utils/constants/offer';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import CalendarIcon from '@tenants/ticketmaster/app/assets/icons/calendar.svg';
    import LocationIcon from '@tenants/ticketmaster/app/assets/icons/location.svg';
    import { IMAGE_SIZE } from '@tenants/ticketmaster/app/utils/constants/imageData';
    import { getImageByWidth, getImageModel } from '@tenants/ticketmaster/app/utils/imageModelUtils';
    import GiftCardChip from '@tenant/app/components/chips/GiftCardChip';
    import HotelCancellationPolicy from '../TheCheckout/HotelCancellationPolicy';

    export default {
        name: 'OrderConfirmationHotelReservationInfo',
        components: {
            CalendarIcon,
            LocationIcon,
            StarRating,
            HotelCancellationPolicy,
            GiftCardChip,
            BaseTooltip,
            BaseIcon
        },
        props: {
            hotel: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                default: null
            },
            isPackageOffer: {
                type: Boolean,
                default: false
            },
            hasHotelReservationOnly: {
                type: Boolean,
                required: true
            },
            giftCard: {
                type: Object,
                default: () => {}
            }
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theOrderConfirmation'
        },
        computed: {
            period( ) {
                const { accommodation } = this.hotel;
                let period = this.$options.filters.datePeriod(
                    accommodation.startDateTime,
                    accommodation.endDateTime,
                    {
                        full: 'eee • MMM d, yyyy',
                        time: 'p',
                        period: 'eee • MMM d',
                        periodFull: 'eee • MMM d, yyyy'
                    },
                    documentUtilsGetLang( ),
                    false
                );

                return `${period} (${this.$t( '_common:terms.nightStayWithCount', { count: accommodation.nights } ) })`;
            },
            hotelImageSrc( ) {
                if ( this.hotel.packageOffer.type === offerConstants.TYPES.PRE_BUNDLE_PACKAGE ) {
                    return getImageByWidth( IMAGE_SIZE.LARGE, getImageModel( this.hotel.packageOffer.imageUrl ) );
                }
                return getImageByWidth( IMAGE_SIZE.LARGE, this.hotel.image );
            },
            directionsUrl() {
                const hotelLocation = {
                    street: this.hotel.address.address,
                    cityName: this.hotel.address.cityName,
                    stateCode: this.hotel.address.stateCode,
                    countryCode: this.hotel.address.countryCode,
                };

                if ( !this.event ) {
                    return getRouteToPoint( hotelLocation );
                }

                const eventLocation = {
                    street: this.event.location.address,
                    cityName: this.event.location.cityName,
                    stateCode: this.event.location.stateCode,
                    countryCode: this.event.location.countryCode,
                };

                const directionsUrl = getRouteBetweenTwoPoints( eventLocation, hotelLocation );

                return directionsUrl;
            },
            showGiftCard( ) {
                return this.giftCard?.amount;
            },
            hotelConfirmationNumber() {
                return this.$t( 'hotelConfirmationNumber', { number: this.hotel.supplierConfirmationNumber } );
            },
        }
    };
</script>

<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

.gst-order-confirmation__hotel-info {
    display: flex;
    width: 80%;
    margin-top: theme-spacing( 6 );
    align-items: center;
    justify-content: center;

    .image-container,
    .info-container {
        flex: 1;
    }

    .image-container {
        img {
            min-height: 200px;
            object-fit: cover;
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        margin-left: theme-spacing( 4 );

        div {
            display: flex;
            line-height: line-height( 'm' );
            color: theme-color( 'quaternary' );
            font-size: font-size( 'xxs' );
            align-items: center;

            .info-container__wrapper-icon {
                width: 15px;

                svg {
                    width: 15px;
                }
            }
        }

        .info-container__confirmation-number {
            font-size: font-size( 'xxs' );
        }

        .event-title-container {
            margin-bottom: theme-spacing( 2 );

            .event-title {
                line-height: line-height( 'xxl' );
                font-size: font-size( 'm' );
                font-weight: font-weight( 'bold' );
            }

            .event-rating {
                position: relative;
                bottom: 1px;
                margin-left: theme-spacing( 2 );
            }
        }

        button {
            width: 220px;
            padding: theme-spacing( 2 ) theme-spacing( 9 );
            margin-bottom: theme-spacing( 2 );
            background: theme-color( 'primary' );
            color: white;
            font-weight: font-weight( 'large' );
            border-radius: 4px;
        }

        .directions {
            line-height: line-height( 'm' );
            margin-top: theme-spacing( 1 );
            color: theme-color( 'primary' );
            font-size: font-size( 'xxs' );
            margin-left: theme-spacing( 6 );
            cursor: pointer;
        }

        .location-img,
        .tickets-img {
            position: relative;
            left: 2px;
        }

        .check-icon {
            .gst-svg-icon {
                fill: theme-color( 'success' );
            }
        }

        .cancellation-policy-label {
            color: theme-color( 'success' );
        }
    }

    .freeCancellation {
        color: theme-color( 'success' );
    }

    .gst-order-confirmation__hotel-info__tooltip-activator {
        display: inline-block;
        vertical-align: text-top;

        .gst-svg-icon {
            stroke: theme-color( 'primary' ) !important;
        }
    }

    .gst-order-confirmation__hotel-info__tooltip-activator:hover {
        .gst-svg-icon {
            stroke: theme-color( 'primary' ) !important;
        }
    }

    .gst-order-confirmation__hotel-info__icon {
        path {
            stroke: theme-color( 'tertiary' );
        }
    }

    @include mobile-only {
        width: 90% !important;
        flex-direction: column;

        .info-container {
            width: 100% !important;
        }
    }
}

.gst-order-confirmation__hotel-info__tooltip.v-tooltip__content {
    line-height: font-size( 'l' );
    padding: theme-spacing( 4 );
    background: theme-color( 'white' ) !important;
    color: theme-color( 'quaternary' );
    font-size: font-size( 's' );
    max-width: 350px;
    box-shadow: 0 2px 6px rgba( theme-color-hex( 'black' ), 0.25 );
    opacity: 1 !important;
}
</style>
