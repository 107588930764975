import Vue from 'vue';
import providerConstants from '@tenant/app/utils/constants/rokt';

const provider = {
    loadScript: async ( ) => {
        const loadBundle = ( ) => {
            return new Promise( ( resolve ) => {
                const options = {
                    async: true,
                    fetchpriority: 'high',
                    crossOrigin: 'anonymous',
                    id: providerConstants.SCRIPT_ID
                };
                Vue.loadScript( providerConstants.SCRIPT_URL, 'text/javascript', options )
                    .then( ( ) => {
                        resolve( true );
                    } )
                    .catch( ( ) => resolve( false ) );
            } );
        };

        const respBundle = await loadBundle( );

        return respBundle;
    },
    getLauncher: async ( ) => {
        if ( await provider.loadScript() ) {
            if ( window.Rokt ) {
                const launcher = await window.Rokt.createLauncher( {
                    accountId: providerConstants.ACCOUNT_ID,
                    sandbox: process.env.NODE_ENV === 'development'
                } );
                return launcher;
            }
        }

        return false;
    }
};

export default provider;
