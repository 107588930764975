<template>
    <div class="gst-order-confirmation-payment d-flex">
        <div class="gst-order-confirmation-payment__summary d-flex">
            <span>{{ $t('paymentSummary') }}</span>
            <span tabindex="0" @click="showMoreDetails = !showMoreDetails" @keypress.enter="showMoreDetails = !showMoreDetails">{{ $t(!showMoreDetails ? 'viewDetails' : 'closeDetails') }}</span>
        </div>
        <div class="gst-order-confirmation-payment__method d-flex">
            <div class="payment-info d-flex">
                <span>{{ $t('paymentMethod') }}</span>
                <span class="payment-info__total">{{ $t('total') }}: {{ totalPrice | currencyFilter( currency ) }} </span>
            </div>
            <div class="card-info"
                :class="{
                    'd-flex justify-space-between': isPackageOffer
                }">
                <div class="d-flex align-center">
                    {{ paymentInfo.card.type }} <div class="card-info__separator"></div> {{ paymentInfo.card.last4 }}
                </div>
                <span v-if="isPackageOffer" class="card-info__sales_final">
                    {{ $t('allSalesFinal') }}
                </span>
            </div>
        </div>
        <v-expand-transition>
            <div v-if="showMoreDetails" class="gst-order-confirmation-payment__details">
                <h5 class="mt-4 mb-2">
                    {{ titlePaymentItems }}
                </h5>
                <div class="detail__item d-flex flex-wrap align-end">
                    <div class="detail__item-row">
                        <div class="detail__item-name flex-grow-1 u-text-transform-by-language">
                            <div v-for="(item, key) in paymentItems" :key="key" class="mb-0">
                                <span class="mr-1">{{ item.name }}</span>
                                <span>x {{ item.quantity }}</span>
                            </div>
                        </div>
                        <div class="detail__item-price flex-shrink-0">
                            {{ totalBasePrice | currencyFilter( currency ) }}
                        </div>
                    </div>
                    <div v-if="feesHotelsIncludedAmount" class="detail__item-row-variant-2 mt-2">
                        <div class="detail__item-name">
                            <span>{{ $t( '_common:terms.feesIncluded' ) }}</span>
                        </div>
                        <div class="detail__item-price flex-shrink-0">
                            {{ feesHotelsIncludedAmount | currencyFilter( currency ) }}
                        </div>
                    </div>
                </div>
                <h5 class="mt-4 mb-2">
                    {{ $t('fees') }}
                </h5>
                <div v-if="hasFees" class="detail__item d-flex flex-wrap align-end">
                    <div v-if="fees.length" class="detail__item-row">
                        <div v-for="(item, key) in fees" :key="key" class="d-flex flex-grow-1 align-center justify-between u-width-100">
                            <div class="detail__item-name flex-grow-1 u-text-transform-by-language">
                                <div v-if="!item.isOrderFee || item.isOrderFee && item.quantity > 1">
                                    <span class="mr-1">{{ item.name }}:</span>
                                    <span class="mr-1">{{ item.price | currencyFilter( currency ) }}</span>
                                    <span>x {{ item.quantity }}</span>
                                </div>
                                <div v-else>
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="detail__item-price flex-shrink-0">
                                {{ item.amount | currencyFilter( item.currency ) }}
                            </div>
                        </div>
                    </div>
                    <div v-if="feesHotelsNotIncludedAmount" class="detail__item-row-variant-2 mt-4 mt-md-2">
                        <div class="detail__item-name">
                            <span>{{ $t( '_common:terms.feesNotIncluded' ) }}</span>
                            <BaseTooltip
                                bottom
                                :text="feesHotelsNotIncludedTooltipContent"
                                class-content="gst-order-confirmation-payment__tooltip-content"
                                class="gst-order-confirmation-payment__tooltip">
                                <HelpIcon />
                            </BaseTooltip>
                        </div>
                        <div class="detail__item-price flex-shrink-0">
                            {{ feesHotelsNotIncludedAmount | currencyFilter( currency ) }}
                        </div>
                    </div>
                </div>
                <div v-else class="detail__item d-flex flex-row align-end">
                    ---
                </div>
                <template v-if="shippingInformation">
                    <h5 class="mt-4 mb-2">
                        {{ $t('delivery') }}
                    </h5>
                    <div class="d-flex flex-row align-end">
                        <div class="detail__item-name flex-grow-1 ">
                            {{ shippingInformation.name }}
                        </div>
                        <div class="detail__item-price flex-shrink-0">
                            {{ shippingInformation.price | currencyFilter( currency ) }}
                        </div>
                    </div>
                </template>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
    import feeConstants from '@core/utils/constants/fee';
    import BaseTooltip from '@core/shared/components/tooltips/BaseTooltip.vue';
    import shippingFilter from '@core/filters/localFilters/shipping';
    import HelpIcon from '@tenant/app/assets/icons/help.svg';
    import FeesContainerMixin from '../TheCheckout/Mixins/FeesContainerMixin';

    export default {
        name: 'OrderConfirmationPaymentInfo',
        components: {
            BaseTooltip,
            HelpIcon
        },
        mixins: [ FeesContainerMixin ],
        props: {
            currency: {
                type: String,
                required: true
            },
            paymentItems: {
                type: Array,
                required: true
            },
            hasHotelReservationOnly: {
                type: Boolean,
                required: true
            },
            isPackageOffer: {
                type: Boolean,
                default: false
            },
            order: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                showMoreDetails: false,
            };
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theOrderConfirmation'
        },
        filters: {
            shippingFilter
        },
        computed: {
            totalPrice( ) {
                return this.order.totalAmount;
            },
            totalBasePrice( ) {
                return this.order.items.reduce( ( acc, item ) => {
                    return acc + item.price;
                }, 0 );
            },
            paymentInfo( ) {
                return this.order.paymentInfo;
            },
            shippingInformation() {
                const itemWithShipping = this.order.items.find( item => item.shipping );
                if ( !itemWithShipping ) {
                    return false;
                }
                const { charges, shipping } = itemWithShipping ;
                const price = charges.find( charge => charge.type === feeConstants.TYPES.DELIVERY ).fee;

                return {
                    name: shipping.short,
                    price
                };
            },
            titlePaymentItems: function( ) {
                if ( this.hasHotelReservationOnly ) {
                    return this.$t( '_common:terms.hotelAddOn' );
                }
                return this.$t( '_common:terms.bundleTicketsHotel' );
            }
        },
    };
</script>

<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-order-confirmation-payment {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .payment-info {
            line-height: line-height( 'xxxl' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'bold' );
            align-items: center;
            justify-content: space-between;
        }

        .gst-order-confirmation-payment__summary {
            width: 100%;
            line-height: line-height( 'xxl' );
            padding-bottom: theme-spacing( 4 );
            margin-top: theme-spacing( 5 );
            font-size: font-size( 'm' );
            font-weight: font-weight( 'bold' );
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #E3E3E3;

            span:nth-child(2) {
                color: theme-color( 'primary' ) !important;
                cursor: pointer;
            }
        }

        .gst-order-confirmation-payment__method {
            width: 100%;
            padding-bottom: theme-spacing( 4 );
            margin-top: theme-spacing( 5 );
            flex-direction: column;

            .payment-info {
                line-height: line-height( 'xxxl' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'bold' );
                align-items: center;
                justify-content: space-between;
            }

            .card-info {
                display: flex;
                line-height: line-height( 'm' );
                margin-top: theme-spacing( 2 );
                color: #999999;
                font-size: font-size( 'xxs' );
                font-weight: font-weight( 'bold' );
                text-transform: uppercase;
                align-items: center;

                .card-info__separator {
                    width: 23px;
                    margin: theme-spacing( 0 ) theme-spacing( 1 );
                    border-bottom: 1px solid #999999;
                }

                .card-info__sales_final {
                    line-height: line-height( 'm' );
                    font-size: font-size( 'xxs' );
                    font-weight: font-weight( 'regular' );
                    text-transform: none;
                }
            }
        }

        .gst-order-confirmation-payment__details {
            width: 100%;
            padding-bottom: theme-spacing( 6 );
            border-top: 1px solid #E3E3E3;

            h5 {
                line-height: line-height( 'xxxl' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 'l' );
                font-weight: font-weight( 'large' );
            }

            .detail__item {
                padding-bottom: theme-spacing( 6 );
                border-bottom: 1px solid #E3E3E3;
            }

            .detail__item-name,
            .detail__item-price {
                line-height: line-height( 'xl' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 's' );
                font-weight: font-weight( 'regular' );
            }

            .detail__item-row {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
            }

            .detail__item-row-variant-2 {
                display: flex;
                flex-grow: 1;

                .detail__item-name {
                    display: flex;
                    line-height: line-height( 'm' );
                    padding-right: theme-spacing( 2 );
                    color: theme-color( 'tertiary' );
                    font-size: font-size( 'xxs' );
                    flex-direction: row;
                    flex-grow: 1;

                    span {
                        margin-right: theme-spacing( 4 );
                    }

                    svg {
                        height: 18px;
                        width: 18px;

                        .gst-svg-icon {
                            stroke: theme-color( 'tertiary' ) !important;
                        }

                        .gst-svg-icon:nth-child(2) {
                            fill: transparent !important;
                        }
                    }
                }

                .detail__item-price {
                    line-height: line-height( 'm' );
                    color: theme-color( 'tertiary' );
                    font-size: font-size( 'xxs' );
                }
            }
        }
    }

    .gst-order-confirmation-payment__tooltip-content.v-tooltip__content {
        @include border-radius( 'xs' );

        width: 316px !important;
        padding: theme-spacing( 4 ) !important;
        background: theme-color( 'white' ) !important;
        color: theme-color( 'quaternary' ) !important;
        box-shadow: 0 2px 6px rgba( 0, 0, 0, 0.25 ) !important;
        opacity: 1 !important;
    }
</style>
