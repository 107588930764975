<template>
    <div></div>
</template>

<script>
    import apiRoktLauncher from '@tenant/app/api/apiRoktLauncher';

    export default {
        name: 'OrderConfirmationRokt',
        components: { },
        props: {
            order: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                showMoreDetails: false,
            };
        },
        async mounted( ) {
            const launcher = await apiRoktLauncher.getLauncher( );
            if ( launcher ) {
                await launcher.selectPlacements( {
                    attributes: {
                        // Recommended
                        email: this.order.billingDetails.emailAddress,
                        // Transaction
                        amount: this.order.totalAmount,
                        quantity: this.order.items.length,
                        // Consumer
                        firstname: this.order.billingDetails.firstName,
                        lastname: this.order.billingDetails.lastName,
                        // Address
                        billingcity: this.order.billingDetails.city,
                        billingstate: this.order.billingDetails.stateCode,
                        billingzipcode: this.order.billingDetails.zipCode,
                        billingcountry: this.order.billingDetails.country,
                    },
                } );
            }
        }
    };
</script>
