<template>
    <div class="gst-order-confirmation__experience-info">
        <div class="d-flex flex-column flex-md-row u-width-100">
            <div class="image-container">
                <img alt="" :src="imageUrl" />
            </div>
            <div class="info-container d-flex flex-column ml-0 ml-md-4">
                <div class="d-flex flex-row align-center justify-space-between mt-3 mb-1 mt-md-0 mb-md-3">
                    <h5>{{ name || hotel.name }}</h5>
                    <StarRating :value="hotel.award.rating" class="star-rating d-flex align-center" />
                </div>
                <div class="d-flex flex-row align-center mb-1 mb-md-2">
                    <BaseIcon symbol-id="icons--calendar" class="mr-1" />
                    <p>{{ eventDate }}</p>
                </div>
                <div class="d-flex flex-row align-center">
                    <BaseIcon symbol-id="icons--location" class="mr-3" />
                    <p>{{ eventLocation }}</p>
                </div>
                <a :href="event.directionsUrl" target="_blank" class="directions mt-1 mb-1 mb-md-2">
                    <strong>{{ $t('getDirections') }}</strong>
                </a>
                <div class="d-flex flex-row align-center mb-1 mb-md-2">
                    <BaseIcon symbol-id="icons--tickets" class="mr-1" />
                    <p>{{ eventTicketQuantity }}</p>
                </div>
                <div class="info-container__highlights d-flex flex-column mt-4 mb-6 mb-md-8">
                    <h6 class="mb-2 mb-md-3">
                        {{ $t( '_common:terms.yourExperienceIncludes') }}:
                    </h6>
                    <Highlights :data="highlightsItems" />
                </div>
                <button @click="onClickDo">
                    {{ $t('claimTickets') }}
                </button>
            </div>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="!hotelReservation.orderNumber" class="warning-container" v-html="warningText">
        </div>
    </div>
</template>

<script>
    import i18next from  'i18next';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import StarRating from '@core/shared/components/misc/StarRating';
    import Highlights from '@tenants/ticketmaster/app/components/misc/Highlights/Highlights.vue';
    import highlightTypeConstants from '@tenants/ticketmaster/app/utils/constants/highlights';
    import {
        getDescriptionForHotel as getPackageHighlightDescriptionForHotel,
        getDescriptionForTicket as getPackageHighlightDescriptionForTicket
    } from '@tenants/ticketmaster/app/utils/packageHighlightsUtils';
    import { sortAndCombineLists as sortAndCombineHighlights } from '@tenant/app/utils/highlightsUtils';

    export default {
        name: 'OrderConfirmationExperienceInfo',
        components: {
            StarRating,
            BaseIcon,
            Highlights
        },
        props: {
            name: {
                type: String,
                default: ''
            },
            imageUrl: {
                type: String,
                default: '',
            },
            highlights: {
                type: Array,
                default: ( ) => [ { type: '', description: '' } ]
            },
            hotel: {
                type: Object,
                default: ( ) => { return { name: '', award: { } }; }
            },
            hotelReservation: {
                type: Object,
                default: ( ) => { return { accommodation: { }, room: { name : '' } }; }
            },
            event: {
                type: Object,
                default: ( ) => { return { address: { }, startDate: new Date( ), endDate: new Date( ), startDateHasTime: true }; }
            },
            eventTicket: {
                type: Object,
                default: ( ) => { return { }; }
            },
            billingDetails: {
                type: Object,
                default: ( ) => { return { email:'' }; }
            }
        },
        computed: {
            isMobile( ) {
                return this.$vuetify.breakpoint.smAndDown;
            },
            eventTicketQuantity() {
                return i18next.t( '_common:terms.standardTicket', { count: this.eventTicket.ticket.quantity } );
            },
            eventDate() {
                const { startDate, endDate, startDateHasTime } = this.event;

                return this.$options.filters.eventPeriod( startDate, endDate, startDateHasTime );
            },
            eventLocation( ) {
                const { address } = this.event;

                return `${address.venueName}, ${address.city}, ${address.stateCode}`;
            },
            hotelReservationPeriod( ) {
                const { accommodation } = this.hotelReservation;

                return this.$options.filters.reservationDatePeriod(
                    new Date( accommodation.startDateTime ),
                    new Date( accommodation.endDateTime ),
                    true
                );
            },
            packageHighlightForHotel( ) {
                return getPackageHighlightDescriptionForHotel( this.highlights )
                    || this.$t( '_common:terms.oneNightStayInDoubleRoom' );
            },
            packageHighlightForTicket( ) {
                return getPackageHighlightDescriptionForTicket( this.highlights )
                    || this.$t( '_common:terms.twoStandardTickets' );
            },
            highlightsItems( ) {
                const initialItems = [
                    {
                        type: highlightTypeConstants.TYPES.HOTEL,
                        description: this.packageHighlightForHotel,
                        subtitle: this.hotelReservationPeriod
                    },
                    {
                        type: highlightTypeConstants.TYPES.TICKET,
                        description: this.packageHighlightForTicket
                    }
                ];

                return sortAndCombineHighlights( initialItems, this.highlights );
            },
            warningText( ) {
                return this.$t( 'warningHotelConfirmation', { email: this.billingDetails.emailAddress, interpolation: { escapeValue: false } } );
            }
        },
        methods: {
            onClickDo( ) {
                window.open( this.eventTicket.redemptionUrl, '_blank' ).focus();
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-order-confirmation__experience-info {
        width: 80%;
        margin-top: theme-spacing( 8 );

        .image-container {
            width: 50%;

            img {
                min-height: 200px;
                object-fit: cover;
            }
        }

        .info-container {
            width: 50%;

            p {
                margin-bottom: 0;
            }

            div {
                line-height: line-height( 'm' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 'xxs' );

                .info-container__wrapper-icon {
                    width: 15px;

                    svg {
                        width: 15px;
                    }
                }
            }

            button {
                width: 220px;
                padding: theme-spacing( 2 ) theme-spacing( 9 );
                margin-bottom: theme-spacing( 2 );
                background: theme-color( 'primary' );
                color: white;
                font-weight: font-weight( 'large' );
                border-radius: 4px;
            }

            .directions {
                width: fit-content;
                line-height: line-height( 'm' );
                color: theme-color( 'primary' );
                font-size: font-size( 'xxs' );
                margin-left: theme-spacing( 6 );
                cursor: pointer;
            }

            .info-container__highlights {
                h6 {
                    color: theme-color( 'black' );
                    font-size: font-size( 's' );
                    font-weight: font-weight( 'large' );
                }

                .gst-highlight-item {
                    color: theme-color( 'tertiary' ) !important;
                }
            }
        }

        .warning-container {
            line-height: line-height( 'xxxl' );
            padding: theme-spacing( 4 );
            margin-top: theme-spacing( 3 );
            background-color: theme-color( 'primary-lighten-1' );
            font-size: font-size( 'm' );
            border-radius: 1px;
        }

        @include mobile-only {
            width: 90% !important;
            flex-direction: column;

            .image-container {
                width: 100%;

                img {
                    width: 100%;
                    min-height: unset;
                }
            }

            .info-container {
                width: 100%;

                .gst-experience-container__highlights {
                    h6 {
                        font-size: font-size( 'xxs' );
                    }

                    .gst-highlight-item {
                        font-size: font-size( 'xxs' );
                    }
                }
            }
        }
    }
</style>