<template>
    <div class="gst-order-confirmation d-flex">
        <OrderConfirmationRokt v-if="isHotelOnlyFlow" :order="order" />
        <div class="gst-order-confirmation__content d-flex">
            <div class="gst-order-confirmation__header-container d-flex">
                <span class="title">{{ title }}</span>
                <span v-if="isExperienceOffer && !hotelReservationOrderNumber" class="subtitle">
                    {{ $t('_common:terms.order') }} #{{ eventTicketOrderNumber }}
                </span>
                <template v-else>
                    <span v-if="hasTickets" class="subtitle">
                        {{ $t('ticketsOrder') }} #{{ eventTicketOrderNumber }}
                    </span>
                    <span v-if="hasHotelReservation" class="subtitle hotel-number">
                        {{ $t('hotelOrder') }} #{{ hotelReservationOrderNumber }}
                    </span>
                </template>
            </div>
            <OrderConfirmationExperienceInfo
                v-if="isExperienceOffer"
                v-bind="propsExperience" />
            <template v-else>
                <OrderConfirmationTicketsInfo
                    v-if="hasTickets"
                    :event="event"
                    :ticket="ticket" />
                <OrderConfirmationHotelReservationInfo
                    v-if="hasHotelReservation"
                    :event="event"
                    :hotel="hotel"
                    :is-package-offer="isPackageOffer"
                    :has-hotel-reservation-only="hasHotelReservationOnly"
                    :gift-card="giftCard" />
            </template>
            <OrderConfirmationPaymentInfo
                :currency="currency"
                :payment-items="paymentItems"
                :has-hotel-reservation-only="hasHotelReservationOnly"
                :order="order"
                :is-package-offer="isPackageOffer"
                class="gst-order-confirmation__payment-info" />
            <div v-if="showGoMobileSection" class="gst-order-confirmation__go-mobile d-flex">
                <MobileTicket />
                <span>
                    <strong>{{ $t('goMobile') }}</strong>
                    {{ $t('goMobileInfo') }}
                </span>
            </div>
            <BaseButton
                v-if="hasTickets"
                class="gst-btn"
                :data-test-id="$testId('button.buyAgain')"
                @click="onClickBuyAgainDo">
                {{ $t('buyAgainButton.title') }}
            </BaseButton>
            <div class="gst-order-confirmation__things-to-know d-flex">
                <div class="title">
                    {{ $t('thingsToKnow') }}
                </div>
                <CheckoutNotes
                    v-if="!!checkoutNotes"
                    class="mt-7"
                    :header="checkoutNotes.data.package_flows__event_info_header"
                    :content="checkoutNotes.data.package_flows__event_info_content" />
                <div class="manage-account-container d-flex" tabindex="0" @click="onClickManageAccountDo" @keypress.enter="onClickManageAccountDo">
                    <div class="manage-account d-flex">
                        <span>{{ $t('manageYourAccount') }}</span>
                        <span><ChevronRight /></span>
                    </div>
                    <div class="help-center d-flex">
                        {{ $t('helpCenter') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import productsConstants from '@core/utils/constants/products';
    import offerConstants from '@core/utils/constants/offer';
    import feeConstants from '@core/utils/constants/fee';
    import { getRouteToPoint } from '@core/utils/locationMapUtils';
    import { isMobileTicket } from '@core/utils/shippingOptionsUtils';
    import EventFlowMixin from '@tenant/app/mixins/EventFlowMixin';
    import { openTicketMasterLoginPage } from '@tenant/app/utils/tenantUrlslUtils';
    import ViewDocumentTitleMixin from '@tenant/app/mixins/ViewDocumentTitleMixin';
    import ChevronRight from '@tenants/ticketmaster/app/assets/icons/chevron_right.svg';
    import MobileTicket from '@tenants/ticketmaster/app/assets/icons/mobile_ticket.svg';
    import OrderConfirmationTicketsInfo from './TheOrderConfirmation/OrderConfirmationTicketsInfo.vue';
    import OrderConfirmationHotelReservationInfo from './TheOrderConfirmation/OrderConfirmationHotelReservationInfo.vue';
    import OrderConfirmationPaymentInfo from './TheOrderConfirmation/OrderConfirmationPaymentInfo.vue';
    import OrderConfirmationRokt from './TheOrderConfirmation/OrderConfirmationRokt.vue';
    import OrderConfirmationExperienceInfo from './TheOrderConfirmation/OrderConfirmationExperienceInfo.vue';
    import CheckoutNotes from './TheCheckout/Containers/CheckoutNotes.vue';

    export default {
        name: 'OrderConfirmation',
        components: {
            MobileTicket,
            ChevronRight,
            OrderConfirmationTicketsInfo,
            OrderConfirmationHotelReservationInfo,
            OrderConfirmationPaymentInfo,
            BaseButton,
            OrderConfirmationRokt,
            OrderConfirmationExperienceInfo,
            CheckoutNotes
        },
        mixins: [
            EventFlowMixin,
            ViewDocumentTitleMixin
        ],
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theOrderConfirmation'
        },
        testIdOptions: {
            keyPrefix: 'orderConfirmation'
        },
        computed: {
            routeParams( ) {
                return this.$route.params;
            },
            order( ) {
                return this.routeParams.order;
            },
            checkoutNotes( ) {
                return this.routeParams.checkoutNotes;
            },
            legacyEventId( ) {
                return this.routeParams.legacyEventId;
            },
            eventTicket( ) {
                return this.order.items
                    .find( item => item.productTypeId === productsConstants.TYPES.TICKET );
            },
            eventTicketItems( ) {
                return this.eventTicket.offers;
            },
            eventTicketOrderNumber( ) {
                return this.eventTicket.orderNumber;
            },
            hotelReservation( ) {
                return this.order.items
                    .find( item => item.productTypeId === productsConstants.TYPES.HOTEL_RESERVATION );
            },
            hasHotelReservation( ) {
                return !!this.hotelReservation;
            },
            hasHotelReservationOnly( ) {
                return this.hasHotelReservation && this.order.items.length === 1;
            },
            hotelReservationOrderNumber( ) {
                return this.hotelReservation.orderNumber;
            },
            hasTickets( ) {
                return !!this.eventTicket;
            },
            packageOffer( ) {
                return this.order.packageOffer;
            },
            isExperienceOffer( ) {
                return this.isPackageOffer && this.packageOffer.type === offerConstants.TYPES.EXPERIENCES_PACKAGE;
            },
            title( ) {
                if ( this.isExperienceOffer ) {
                    return this.$t( 'experienceTitle' );
                }
                if ( this.hasHotelReservationOnly ) {
                    return this.$t( 'hotelOnlyTitle' );
                }
                return this.$t( 'hotelAndTicketsTitle' );
            },
            event( ) {
                if ( !this.hasTickets ) {
                    return null;
                }
                const { name, imageUrl: image, startDateTime, endDateTime, venueName, address, hasStartTime } = this.eventTicket.event;
                const directionsUrl = getRouteToPoint( {
                    street: address.street,
                    cityName: address.cityName,
                    stateCode: address.stateCode,
                    countryCode: address.countryCode,
                } );

                return {
                    name,
                    image,
                    startDateTime,
                    endDateTime,
                    hasStartTime,
                    venueName,
                    location: {
                        ...address,
                    },
                    directionsUrl
                };
            },
            ticket( ) {
                if ( !this.hasTickets ) {
                    return false;
                }
                const { eventTicket } = this;
                const { ticket } = eventTicket;
                const { row, section, seats } = ticket;

                return {
                    packageOfferId: eventTicket.packageOfferId,
                    redemptionUrl: eventTicket.redemptionUrl,
                    seat: {
                        row,
                        section,
                        seats
                    }
                };
            },
            hotel( ) {
                if ( !this.hasHotelReservation ) {
                    return null;
                }

                const { hotelReservation } = this;
                const { accommodation, hotel } = hotelReservation;
                const { room } = hotel;

                return {
                    name: hotel.name,
                    address: hotel.address,
                    image: hotel.image,
                    accommodation: accommodation,
                    roomCancellationPolicy: room.rate.cancellationPolicies,
                    roomAmenities: hotel.room.amenities,
                    award: hotelReservation.hotel.award,
                    currency: this.order.currency,
                    roomRate: hotelReservation.basePrice,
                    accommodationNights: hotelReservation.accommodation.nights,
                    packageOffer: this.packageOffer,
                    supplierConfirmationNumber: hotelReservation.supplierConfirmationNumber
                };
            },
            currency( ) {
                return this.order.currency;
            },
            paymentItems( ) {
                let ret = [ ];

                if ( this.hasTickets ) {
                    const { eventTicketItems } = this;

                    const tickets = eventTicketItems.reduce( ( acc, item ) => {
                        acc = [
                            ...acc,
                            {
                                name: item.name,
                                quantity: item.quantity
                            }
                        ];

                        return acc;
                    }, [] );

                    ret = [
                        ...ret,
                        ...tickets
                    ];
                }
                if ( this.hasHotelReservation ) {
                    const { hotelReservation } = this;

                    ret.push( {
                        name: hotelReservation.hotel.room.name.toLowerCase(),
                        quantity: hotelReservation.accommodation.roomsCount
                    } );
                }

                return ret;
            },
            showGoMobileSection() {
                if ( !this.hasTickets ) {
                    return false;
                }
                const deliveryInfo = this.eventTicket.charges.find( item => item.type === feeConstants.TYPES.DELIVERY ).info || {};

                return isMobileTicket( deliveryInfo );
            },
            isPackageOffer() {
                return this.ticket && !!this.ticket.packageOfferId;
            },
            propsExperience( ) {
                const { hotelReservation, eventTicket, packageOffer } = this;
                const { hotel } = hotelReservation;
                const { event } = eventTicket;
                const { address } = event;
                const { billingDetails } = this.order;
                const directionsUrl = getRouteToPoint( {
                    street: address.address,
                    cityName: address.cityName,
                    stateCode: address.stateCode,
                    countryCode: address.countryCode,
                } );

                return  {
                    name: packageOffer.name,
                    imageUrl: packageOffer.imageUrl,
                    highlights: packageOffer.highlights,
                    hotel: {
                        name: hotel.name,
                        award: hotel.award,
                    },
                    hotelReservation: {
                        accommodation: hotelReservation.accommodation,
                        room: {
                            name: hotelReservation.hotel.room.name,
                        },
                        orderNumber: hotelReservation.orderNumber
                    },
                    event: {
                        address: {
                            venueName: event.venueName,
                            street: address.street,
                            city: address.cityName,
                            stateCode: address.stateCode,
                            country: address.countryCode,
                        },
                        startDate: event.startDateTime,
                        endDate: event.endDateTime,
                        startDateHasTime: event.hasStartTime,
                        directionsUrl
                    },
                    eventTicket: eventTicket,
                    billingDetails
                };
            },
            giftCard( ) {
                return this.order.giftCard;
            }
        },
        methods: {
            ...mapActions( {
                updateLanguagesForEvent: 'languages/updateLanguagesForEvent'
            } ),
            onClickManageAccountDo() {
                openTicketMasterLoginPage();
            },
            onClickBuyAgainDo() {
                if ( this.isExperienceOffer ) {
                    this.$router.push( { name: 'eventMarketing', params: {
                        id: this.legacyEventId
                    } } );
                } else {
                    this.$router.push( { name: 'eventPackageOffer', params: {
                        id: this.eventTicket.event.id
                    } } );
                }
            },
        },
        mounted( ) {
            if ( !this.order ) {
                this.$router.back( );
                return;
            }
            this.updateDocumentTitle( this.$t( 
                'main:viewsTitle.theEvent.experiences', 
                { package: this.hotelReservation.hotel.name } 
            ) );
            if ( this.eventTicket ) {
                this.updateLanguagesForEvent( this.eventTicket.legacyEventId );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssMixins";
@import "@scssVariables";

.gst-order-confirmation {
    background-color: theme-color( 'white' );
    color: theme-color( 'quaternary' );
    flex-direction: column;
    align-items: center;

    .gst-btn {
        height: 46px;
        width: 80%;
        background-color: theme-color( 'primary' ) !important;
        color: theme-color( 'white' ) !important;
        text-transform: unset !important;
    }

    .gst-order-confirmation__content {
        padding: 0 20%;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
    }

    .gst-order-confirmation__header-container {
        width: 100%;
        padding: 44px;
        margin-top: theme-spacing( 7 );
        background: #0B309A;
        color: white;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            line-height: 43px !important;
            margin-bottom: theme-spacing( 3 );
            font-size: 34px !important;
            font-weight: font-weight( 'bold' );
            font-family: "Averta Std", sans-serif !important;
            text-align: center;
        }

        .subtitle {
            line-height: line-height( 'xxl' ) !important;
            font-size: font-size( 'm' ) !important;
            font-weight: font-weight( 'bold' );
            font-family: "Averta Std", sans-serif !important;
            text-align: center;
        }

        .hotel-number {
            margin-top: theme-spacing( 2 );
        }
    }

    .gst-order-confirmation__payment-info {
        width: 80%;
        margin-top: theme-spacing( 6 );
    }

    .gst-order-confirmation__go-mobile {
        width: 80%;
        line-height: line-height( 'xs' );
        padding: theme-spacing( 4 ) theme-spacing( 6 );
        background: theme-color( 'quinary' );
        color: theme-color( 'quaternary' );
        font-size: font-size( 'xxxs' );
        border-radius: 1px;
        align-items: center;

        span {
            margin-left: theme-spacing( 3 );
        }
    }

    .gst-order-confirmation__things-to-know {
        width: 80%;
        margin-top: theme-spacing( 4 );
        margin-bottom: theme-spacing( 8 );
        flex-direction: column;

        .title {
            line-height: line-height( 'xxl' ) !important;
            padding: theme-spacing( 4 );
            background: linear-gradient( 270deg, #026CDF 0%, #0150A7 100% );
            color: white;
            font-size: font-size( 'l' ) !important;
            font-weight: font-weight( 'bold' );
            font-family: "Averta Std", sans-serif !important;
        }

        .manage-account-container {
            padding: theme-spacing( 4 );
            border: 1px solid theme-color( 'senary' );
            flex-direction: column;
            border-top: 0;

            .manage-account {
                line-height: line-height( 'm' );
                padding-bottom: theme-spacing( 3 );
                color: #000000;
                font-size: font-size( 'xxs' );
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid theme-color( 'senary' );
                cursor: pointer;
            }

            .help-center {
                line-height: line-height( 'm' );
                padding: theme-spacing( 5 ) 0 theme-spacing( 1 );
                color: theme-color( 'primary' );
                font-size: font-size( 'xxs' );
                font-weight: font-weight( 'bold' );
                align-items: center;
                justify-content: center;
            }
        }
    }

    img {
        width: 100%;
    }
}

@include mobile-only {
    .gst-order-confirmation {
        .gst-order-confirmation__content {
            padding: 0 !important;
        }

        .gst-btn {
            height: 46px;
            width: 95%;
            background-color: theme-color( 'primary' ) !important;
            color: theme-color( 'white' ) !important;
        }

        .gst-order-confirmation__header-container {
            margin-top: theme-spacing( 2 );

            .title {
                line-height: 30px !important;
                font-size: 24px !important;
            }

            .subtitle {
                line-height: 17px !important;
                font-size: 14px !important;
            }
        }

        .gst-order-confirmation__payment-info,
        .gst-order-confirmation__go-mobile,
        .gst-order-confirmation__things-to-know {
            width: 95% !important;
        }
    }
}
</style>
