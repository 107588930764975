<template>
    <div class="gst-order-confirmation__tickets-info">
        <div class="d-flex flex-row align-center justify-center">
            <div class="image-container">
                <img alt="Event Image" :src="event.image" />
            </div>
            <div class="info-container">
                <span class="event-title">{{ event.name }}</span>
                <div class="mb-2">
                    <div class="info-container__wrapper-icon d-flex justify-center">
                        <CalendarIcon />
                    </div>
                    <span class="ml-2">
                        {{ eventPeriod }}
                    </span>
                </div>
                <div class="mb-0">
                    <div class=" info-container__wrapper-icon d-flex justify-center">
                        <LocationIcon />
                    </div>
                    <span class="ml-2">
                        {{ event.venueName }},
                        {{ event.location | fullLocation }}
                    </span>
                </div>
                <a :href="event.directionsUrl" target="_blank" class="directions"><strong>{{ $t('getDirections') }}</strong></a>
                <div class="mt-2 mb-3">
                    <div class=" info-container__wrapper-icon d-flex justify-center">
                        <TicketsIcon />
                    </div>
                    <span class="ml-2">
                        {{ ticket.seat | seatFullLocation }}
                    </span>
                </div>
                <button @click="onClickDo">
                    {{ $t('claimTickets') }}
                </button>
            </div>
        </div>
        <div class="warning-container">
            {{ $t( 'warningAccountAccess', { interpolation: { escapeValue: false } } ) }}
        </div>
    </div>
</template>

<script>
    import CalendarIcon from '@tenants/ticketmaster/app/assets/icons/calendar.svg';
    import LocationIcon from '@tenants/ticketmaster/app/assets/icons/location.svg';
    import TicketsIcon from '@tenants/ticketmaster/app/assets/icons/tickets.svg';

    export default {
        name: 'OrderConfirmationTicketsInfo',
        components: {
            TicketsIcon,
            CalendarIcon,
            LocationIcon,
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.cart.theOrderConfirmation'
        },
        computed: {
            eventPeriod( ) {
                const { startDateTime, endDateTime, hasStartTime } = this.event;

                return this.$options.filters.eventPeriod( startDateTime, endDateTime, hasStartTime );
            }
        },
        methods: {
            onClickDo() {
                window.open( this.ticket.redemptionUrl, '_blank' ).focus();
            },

        }
    };
</script>

<style lang="scss">
    @import "@scssMixins";
    @import "@scssVariables";

    .gst-order-confirmation__tickets-info {
        width: 80%;
        margin-top: theme-spacing( 8 );

        .image-container,
        .info-container {
            flex: 1;
        }

        .image-container {
            img {
                min-height: 200px;
                object-fit: cover;
            }
        }

        .info-container {
            display: flex;
            flex-direction: column;
            margin-left: theme-spacing( 4 );

            div {
                display: flex;
                line-height: line-height( 'm' );
                color: theme-color( 'quaternary' );
                font-size: font-size( 'xxs' );
                align-items: center;

                .info-container__wrapper-icon {
                    width: 15px;

                    svg {
                        width: 15px;
                    }
                }
            }

            .event-title {
                line-height: line-height( 'xxl' );
                margin-bottom: theme-spacing( 2 );
                font-size: font-size( 'm' );
                font-weight: font-weight( 'bold' );
            }

            button {
                width: 220px;
                padding: theme-spacing( 2 ) theme-spacing( 9 );
                margin-bottom: theme-spacing( 2 );
                background: theme-color( 'primary' );
                color: white;
                font-weight: font-weight( 'large' );
                border-radius: 4px;
            }

            .directions {
                width: fit-content;
                line-height: line-height( 'm' );
                margin-top: theme-spacing( 1 );
                color: theme-color( 'primary' );
                font-size: font-size( 'xxs' );
                margin-left: theme-spacing( 6 );
                cursor: pointer;
            }
        }

        .warning-container {
            line-height: line-height( 'l' );
            padding: theme-spacing( 2, 4 );
            margin-top: theme-spacing( 3 );
            margin-right: theme-spacing( n6 );
            background-color: rgba( theme-color-hex( 'warning' ), 0.15 );
            font-size: font-size( 'xs' );
            white-space: pre-line;
        }

        @include mobile-only {
            width: 90% !important;
            flex-direction: column;

            .info-container {
                width: 100% !important;
            }

            .warning-container {
                line-height: line-height( 'l' );
                margin-right: unset;
                font-size: font-size( 'xxs' );
            }
        }
    }
</style>